// @flow
import * as React from 'react';

import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';

import classnames from 'classnames';
import DownloadFormDialog from './DownloadFormDialog';
import StyledCard from './StyledCard';

import type { PrismicWhitePaper } from '~schema';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  whitePaper?: ?PrismicWhitePaper,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
});

const WhitePaperCard = ({
  whitePaper,
  className,
  classes,
  ...styledCardprops
}: Props): React.Node => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Container disableGutters className={classnames(classes.root, className)}>
      <DownloadFormDialog
        open={open}
        handleClose={handleClose}
        data={whitePaper?.data}
      />
      <StyledCard
        {...styledCardprops}
        cardActionAreaProps={{
          onClick: () => handleOpen(),
          disableTouchRipple: true,
        }}
      />
    </Container>
  );
};

WhitePaperCard.defaultProps = {
  className: undefined,
  whitePaper: undefined,
};

export default withStyles<*, *, *>(styles, { name: 'WhitePaperCard' })(WhitePaperCard);
