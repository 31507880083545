// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

import DownloadForm from '~components/DownloadForm';
import useDownloadForm from '~components/DownloadForm/useDownloadForm';
import useDownloadFormValidationSchema from '~components/DownloadForm/useDownloadFormValidationSchema';
import RichText from '~plugins/prismic/components/RichText';
import type { PrismicStructuredTextType, PrismicLinkType } from '~schema';
import Link from '~plugins/i18n/components/Link';
import cleanseZohoData from '../functions/helpers/cleanseZohoData';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'closeButton'
  | 'title'
  | 'description'
  | 'downloadForm'
  | 'downloadFormCheckbox'
  | 'button';

type LinkProps = React.ElementConfig<typeof Link>;

export type Props = {|
  ...$Exact<$Diff<LinkProps, { to: any }>>,
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  open: boolean,
  handleClose: any,
  data: ?{
    name?: ?PrismicStructuredTextType,
    title?: ?PrismicStructuredTextType,
    description?: ?PrismicStructuredTextType,
    document_link?: ?PrismicLinkType,
    zoho_listkey?: ?string,
    zoho_lead_source?: ?string,
    zoho_industry?: ?string,
  },
|};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    '& .MuiPaper-root': {
      padding: theme.spacing(8, 4, 11, 4),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  title: {},
  description: {},
});

const FORM_NAME = 'Guide';

const DownloadFormDialog = ({
  data,
  open,
  handleClose,
  classes,
  className,
}: Props): React.Node => {
  const downloadForm = useDownloadForm();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classnames(classes.root, className)}
    >
      <IconButton
        disableRipple
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <RichText {...data?.title} className={classes.title} />
      </DialogTitle>
      <DialogContent>
        <RichText {...data?.description} className={classes.description} />
      </DialogContent>
      <DialogContent>
        <DownloadForm
          formName={FORM_NAME}
          node={downloadForm}
          listKey={data?.zoho_listkey || undefined}
          contentSource={cleanseZohoData(data?.zoho_lead_source) || undefined}
          inputSector={cleanseZohoData(data?.zoho_industry) || undefined}
          validationSchema={useDownloadFormValidationSchema(downloadForm)}
          fileUrl={data?.document_link?.url || ''}
          classes={React.useMemo(
            () => ({
              root: classes.downloadForm,
              checkbox: classes.downloadFormCheckbox,
            }),
            [classes],
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

DownloadFormDialog.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, *>(styles, { name: 'DownloadFormDialog' })(DownloadFormDialog);
