// @flow
import * as React from 'react';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import FileSaver from 'file-saver';

import TextField from '~plugins/form/components/TextField';
import Form, {
  type Props as FormProps,
} from '~plugins/zoho-form/components/Form';
import useHandleOnSubmitMapValues from '~plugins/zoho-form/hooks/useHandleOnSubmitMapValues';
import withPartialSuccessReponseSnackbar from '~hocs/withPartialSuccessReponseSnackbar';
import CheckboxWithLabel from '~plugins/form/components/CheckboxWithLabel';
import RichText from '~plugins/prismic/components/RichText';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import FormLoadingButton from '~components/FormLoadingButton';
import StyledTextField from '~components/StyledTextField';
import useRecaptcha from '~hooks/useRecaptcha';
import fetchRecaptchaValidation from '~helpers/fetchRecaptchaValidation';
import type { PrismicWhitePaperForm } from '~schema';

import type { DownloadFormValues } from './types';
import handleSubmitMapValues from './helpers/handleSubmitMapValues';
import messages from './messages';

const EnhancedForm = withPartialSuccessReponseSnackbar<
  DownloadFormValues,
  FormProps<DownloadFormValues>,
>()(Form);

const lightColor = '#FFF';
const darkColor = '#f4f5f8';

export type ClassKey = 'root' | 'checkbox' | 'action' | 'disabledButton';

const styles = (theme: Theme) => ({
  root: {},
  checkbox: {
    textAlign: 'left',
    '& .MuiCheckbox-root': {
      color: ({ sliceColor }) =>
        sliceColor === 'Primary' ? theme.palette.common.white : undefined,
    },
    '& a': {
      color: ({ sliceColor }) =>
        sliceColor === 'Primary'
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
  },
  action: {
    margin: theme.spacing(2, 0, 2),
  },
  disabledButton: ({ sliceColor }) => ({
    ...(sliceColor === 'Primary'
      ? {
          backgroundColor: `${theme.palette.grey[400]} !important`,
        }
      : {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        }),
  }),
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<FormProps<DownloadFormValues>, { onSubmitMapValues: any }>>,
  node: ?PrismicWhitePaperForm,
  className?: string,
  sliceColor?: string,
  fileUrl: string,
  inputSector: ?string,
  onSubmitMapValues?: $ElementType<
    FormProps<DownloadFormValues>,
    'onSubmitMapValues',
  >,
};

const DownloadForm = ({
  sliceColor,
  fileUrl,
  initialValues,
  node,
  inputSector,
  onSubmitMapValues,
  className,
  classes,
  ...props
}: Props) => {
  const intl = useIntl();

  const handleSuccess = React.useCallback(
    (unusedValues, { resetForm }) => {
      const siteName = process.env.GATSBY_SITE_NAME;
      const fileName = siteName ? `${siteName} - White Paper` : 'White Paper';
      FileSaver.saveAs(fileUrl, fileName);
      resetForm({});
    },
    [fileUrl],
  );
  const [recaptcha, token] = useRecaptcha();

  const handleSubmit = React.useCallback(
    () => fetchRecaptchaValidation(token),
    [],
  );

  return (
    <EnhancedForm
      {...props}
      node={node}
      messages={messages}
      onSuccess={handleSuccess}
      onSubmit={handleSubmit}
      onSubmitMapValues={useHandleOnSubmitMapValues({
        onSubmitMapValues,
        handleSubmitMapValues,
      })}
      initialValues={React.useMemo(
        () => ({
          name: '',
          email: '',
          phone: '',
          inputSector: inputSector || null,
          ...initialValues,
        }),
        [initialValues, inputSector],
      )}
      className={classnames(classes.root, className)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledTextField
            type="text"
            TextField={TextField}
            name="name"
            label={
              node?.data?.label_name?.text || (
                <FormattedMessage {...messages.labels.name} />
              )
            }
            inputProps={{ 'aria-label': 'Name input field' }}
            color={
              sliceColor && sliceColor !== 'White' ? lightColor : darkColor
            }
            fullWidth
          />
        </Grid>
        {node?.data?.show_phone ? (
          <Grid item xs={12} sm={6}>
            <StyledTextField
              type="text"
              name="phone"
              TextField={TextField}
              label={
                node?.data?.label_phone?.text || (
                  <FormattedMessage {...messages.labels.phone} />
                )
              }
              inputProps={{ 'aria-label': 'Phone input field' }}
              color={
                sliceColor && sliceColor !== 'White' ? lightColor : darkColor
              }
              fullWidth
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={node?.data?.show_phone ? 12 : 6}>
          <StyledTextField
            type="email"
            name="email"
            TextField={TextField}
            label={
              node?.data?.label_email?.text || (
                <FormattedMessage {...messages.labels.email} />
              )
            }
            inputProps={{ 'aria-label': 'Email input field' }}
            color={
              sliceColor && sliceColor !== 'White' ? lightColor : darkColor
            }
            fullWidth
          />
        </Grid>
        {hasRichTextFieldValue(node?.data?.label_accept_privacy_policy) ? (
          <Grid item xs={12}>
            <CheckboxWithLabel
              name="acceptPrivacyPolicy"
              type="checkbox"
              indeterminate={false}
              required
              label={<RichText {...node?.data?.label_accept_privacy_policy} />}
              aria-label={
                node?.data?.label_accept_privacy_policy?.text ||
                intl.formatMessage(messages.labels.privacyPolicy)
              }
              className={classes.checkbox}
              color={
                sliceColor && sliceColor === 'Primary' ? 'secondary' : 'primary'
              }
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
      {recaptcha}
      <FormLoadingButton
        type="submit"
        color={sliceColor && sliceColor === 'Primary' ? 'secondary' : 'primary'}
        spinnerColor={
          sliceColor && sliceColor === 'Primary' ? 'primary' : 'secondary'
        }
        variant="contained"
        aria-label="download"
        fullWidth
        className={classes.action}
        classes={{ disabled: classes.disabledButton }}
      >
        {node?.data?.label_submit?.text || (
          <FormattedMessage {...messages.labels.submit} />
        )}
      </FormLoadingButton>
    </EnhancedForm>
  );
};

DownloadForm.defaultProps = {
  className: undefined,
  sliceColor: undefined,
  onSubmitMapValues: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'DownloadForm' })(DownloadForm);
