// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicWhitePaperForm } from '~schema';

const query = graphql`
  query UseDownloadFormDefaultQuery {
    allPrismicWhitePaperForm {
      nodes {
        lang
        id
        data {
          name {
            text
            html
          }
          title {
            text
            html
          }
          description {
            text
            html
          }
          label_name {
            text
            html
          }
          label_email {
            text
            html
          }
          label_phone {
            text
            html
          }
          show_phone
          label_accept_privacy_policy {
            text
            html
          }
          label_submit {
            text
            html
          }
          error_email {
            text
            html
          }
          error_must_accept_privacy_policy {
            text
            html
          }
          error_required {
            text
            html
          }
          event_error {
            text
            html
          }
          event_reject {
            text
            html
          }
          event_success {
            text
            html
          }
          event_subscribed {
            text
            html
          }
        }
      }
    }
  }
`;

export default function useContactForm(): ?PrismicWhitePaperForm {
  return useFindCurrentLocaleNode<PrismicWhitePaperForm>({
    nodes: useStaticQuery<Query>(query).allPrismicWhitePaperForm?.nodes,
    toQuery: prismicWhitePaperForm => ({ prismicWhitePaperForm }),
    fromQuery: data => data?.prismicWhitePaperForm,
  });
}
