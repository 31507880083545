// @flow
import type { MessageDescriptor } from 'react-intl';

import type { DownloadFormValues } from './types';

const prefix = 'downloadForm';

export default {
  labels: {
    ...({
      name: {
        id: `${prefix}.labels.name`,
        defaultMessage: 'Nombre',
        description: 'Name field label',
      },
      email: {
        id: `${prefix}.labels.email`,
        defaultMessage: 'Email',
        description: 'E-mail field label',
      },
      phone: {
        id: `${prefix}.labels.phone`,
        defaultMessage: 'Phone',
        description: 'Phone field label',
      },
      acceptPrivacyPolicy: {
        id: `${prefix}.labels.acceptPrivacyPolicy`,
        defaultMessage: `Aceptar política de privacidad`,
        description: 'Accept privacy policy field label',
      },
    }: $ObjMap<
      $Diff<DownloadFormValues, { inputSector: any }>,
      <V>() => MessageDescriptor,
    >),
    submit: ({
      id: `${prefix}.labels.submit`,
      defaultMessage: 'Enviar',
      description: 'Submit button label',
    }: MessageDescriptor),
    privacyPolicy: ({
      id: `${prefix}.labels.privacyPolicy`,
      defaultMessage: 'política de privacidad',
      description: 'Privacy policy label',
    }: MessageDescriptor),
  },
  errors: {
    email: ({
      id: `${prefix}.errors.email`,
      defaultMessage: 'Debe ser una dirección de correo válida',
      description: 'Error shown for an email field that has an invalid value',
    }: MessageDescriptor),
    required: ({
      id: `${prefix}.errors.required`,
      defaultMessage: 'Campo obligatorio',
      description: 'Error shown for a required field that has no value',
    }: MessageDescriptor),
    mustAcceptPrivacyPolicy: ({
      id: `${prefix}.errors.mustAcceptPrivacyPolicy`,
      defaultMessage: 'Debes aceptar la política de privacidad',
      description: 'Error shown when privacy policy field is not accepted',
    }: MessageDescriptor),
  },
  onSuccess: ({
    id: `${prefix}.onSuccess`,
    defaultMessage: 'Gracias!',
    description: 'Message shown when a submission has been succesfully sent',
  }: MessageDescriptor),
  onError: ({
    id: `${prefix}.onError`,
    defaultMessage: 'La información no se ha podido enviar',
    description: 'Message shown when a submission has not been accepted',
  }: MessageDescriptor),
  onReject: ({
    id: `${prefix}.onReject`,
    defaultMessage: 'No se pudo enviar la información',
    description: 'Message shown when a submission has not been sent',
  }: MessageDescriptor),
};
