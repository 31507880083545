// @flow
import cleanseZohoData from '../../../functions/helpers/cleanseZohoData';
import type { DownloadFormValues } from '../types';

export default function handleSubmitMapValues(
  originalValues: DownloadFormValues,
) {
  return {
    createLead: {
      Last_Name: originalValues.name,
      Email: originalValues.email,
      Phone: originalValues.phone || undefined,
      Industry: cleanseZohoData(originalValues.inputSector) || undefined,
    },
    subscribe: {
      contactData: {
        'Contact Email': originalValues.email,
        Phone: originalValues.phone || undefined,
      },
    },
  };
}
